<!--<head>-->
<!--<title>西昌市跃林学校</title>-->
<!--<meta name="keyword" content="西昌市,跃林学校，跃林学校"/>-->
<!--<meta name="description" content="西昌市跃林学校，是经国家教育行政主管部门批准成立的九年一贯制寄宿制学校。学校位于交通便利的西昌市马道街道办事处。自2011年申办以来，学校不忘“不抛弃、不放弃”的育人初心，践行“有教无类、因材施教”育人理念，实施“以高水平教学，高效-->
<!--率管理”方针，努力办人民满意的教育。学校始终坚持以教育、教学质量为发展之本；"/>-->
<!--</head>-->
<template>
  <div id='building' style="margin-left: -25px;" >

  <el-card class="school-intro" style="text-align:left;border-radius: 20px;width:350px;margin-top: 1px;color: #dddddd;font-family: 仿宋,serif;" >

    <el-image  class="school-image" :src="h1" style="width: 100%; height:auto; margin-top:0px;margin-left: -40px; border-radius: 20px">
    </el-image>
    <el-image  class="school-gif" :src="gif" style="width: 100%; height:auto; margin-top:10px;margin-left: 0px; border-radius: 20px;text-align: left">
    </el-image>
    <h2 style="text-align: left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;西昌市跃林学校，是经国家教育行政主管部门批准成立的九年一贯制寄宿制学校。学校位于交通便利的西昌市马道街道办事处。自2011年申办以来，学校不忘“不抛弃、不放弃”的育人初心，践行“有教无类、因材施教”育人理念，努力办人民满意的教育。</h2>
  </el-card>

    <el-card class="school-intro3" style="border-radius: 20px;width:350px;margin-top: 10px;margin-left: auto;color: #dddddd;font-family: 仿宋,serif" >
    <h2 style="text-align: left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;学校始终坚持以教育、教学质量为发展之本。近年来，依托“西昌天立教育集团”，与之教育资源共享。使我校的教育常规得到了创新、教师教学能力、学校教育教学质量得到了有效提升。学校针还对进城务工子女、自主搬迁户和留守儿童读书难、管理难等问题，采取全封闭式教学，全时段、全方位托管方式，彻底解决学生回家无人管，课后作业无人辅导等问题。

      十多年的办学历程和教学实践，形成了理念先进、管理严格、校风纯正、学风浓厚的跃林特色，也诠释了跃林人的教育情结。今天的我们正阔步向前，走上了“低进高出，高进优出”良性发展之路。</h2>
      <video style="width: 300px;height: auto;margin-left:-10px;border-color: #dddddd;border-radius: 20px;margin-top: 10px" controls>
        <source src="@/assets/简介.mp4" type="video/mp4" />
      </video>
    </el-card>


    <el-card class="school-intro3" style="border-radius: 20px;width:350px;margin-top: 10px;margin-left: auto;color: #dddddd;font-family: 仿宋" >
      <el-image  class="school-image" :src="scimage" style="width: 100%; height:auto; margin-top:10px;margin-left: 0px; border-radius: 20px">
      </el-image>
    </el-card>



    <el-card class="school-intro3" style="border-radius: 20px;width:350px;margin-top: 10px;margin-left: auto;color: #dddddd;font-family: 仿宋" >
      <el-image  class="school-image" :src="h2" style="width: 100%; height:auto; margin-top:10px;margin-left: 0px; border-radius: 20px">
      </el-image>
      <el-image  class="school-image" :src="p1" style="width: 100%; height:auto; margin-top:10px;margin-left: 0px; border-radius: 20px">
      </el-image>
      <h2>课堂教学</h2>
    </el-card>

    <el-card class="school-intro3" style="border-radius: 20px;width:350px;margin-top: 10px;margin-left: auto;color: #dddddd;font-family: 仿宋" >
      <el-image  class="school-image" :src="p2" style="width: 100%; height:auto; margin-top:10px;margin-left: 0px; border-radius: 20px">
      </el-image>
      <h2>运动会</h2>
    </el-card>

    <el-card class="school-intro3" style="border-radius: 20px;width:350px;margin-top: 10px;margin-left: auto;color: #dddddd;font-family: 仿宋" >
      <el-image  class="school-image" :src="p03" style="width: 100%; height:auto; margin-top:10px;margin-left: 0px; border-radius: 20px">
      </el-image>
      <h2>课外活动</h2>
    </el-card>
    <el-card class="school-intro3" style="border-radius: 20px;width:350px;margin-top: 10px;margin-left: auto;color: #dddddd;font-family: 仿宋" >
      <el-image  class="school-image" :src="p04" style="width: 100%; height:auto; margin-top:10px;margin-left: 0px; border-radius: 20px">
      </el-image>
      <h2>图书阅览室</h2>
    </el-card>

    <el-card class="school-intro3" style="border-radius: 20px;width:350px;margin-top: 10px;margin-left: auto;color: #dddddd;font-family: 仿宋" >
      <el-image  class="school-image" :src="p05" style="width: 100%; height:auto; margin-top:10px;margin-left: 0px; border-radius: 20px">
      </el-image>
      <h2>歌咏比赛</h2>
    </el-card>

    <el-card class="school-intro3" style="border-radius: 20px;width:350px;margin-top: 10px;margin-left: auto;color: #dddddd;font-family: 仿宋" >
      <el-image  class="school-image" :src="p06" style="width: 100%; height:auto; margin-top:10px;margin-left: 0px; border-radius: 20px">
      </el-image>
      <h2>表彰大会</h2>
    </el-card>

    <el-card class="school-intro3" style="border-radius: 20px;width:350px;margin-top: 10px;margin-left: auto;color: #dddddd;font-family: 仿宋" >
      <el-image  class="school-image" :src="p07" style="width: 100%; height:auto; margin-top:10px;margin-left: 0px; border-radius: 20px">
      </el-image>
      <h2>中考誓师</h2>
    </el-card>
  </div>
</template>

<script>

import ElementPlus from "element-plus";


export default {
  name: "introduction",
  isVideo: true,
  player:null,
  data() {
    return {
      schoolName: 'Xichang Yuelin Middle School',
      gif: require('@/assets/gif.gif'),
      scimage:require('@/assets/school.jpg'),
      h1:require('@/assets/53.png'),
      h2:require('@/assets/54.png'),
      p1:require('@/assets/p01.jpg'),
      p2:require('@/assets/p2.jpg'),
      p03:require('@/assets/p03.jpg'),
      p04:require('@/assets/p04.jpg'),
      p05:require('@/assets/p05.jpg'),
      p06:require('@/assets/p06.jpg'),
      p07:require('@/assets/p07.jpg')
    };
  },
}
</script>

<style scoped>
/* 样式可以根据你们学校的风格进行调整 */
.school-intro {
  font-family: Arial, sans-serif;
  max-width: 350px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.school-intro3 {
  font-family: Arial, sans-serif;
  max-width: 750px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}
#building{
  background:url("~@/assets/背景059.jpg");
  width:350px;
  height:3150px;
  border-radius: 20px;
  background-size: cover;

}

h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

ul {
  list-style: none;
  margin-top: 20px;
  padding-left: 0;
}

.school-intro{
border: 1px solid transparent;
border-radius: 15px;
  background-color: rgba(255,255,255,0.1);
  box-shadow: 5px 5px 0 0 rgba(0,0,0,0.2);
}
.school-intro2{
  border: 1px solid transparent;
  border-radius: 15px;
  background-color: rgba(255,255,255,0.1);
  box-shadow: 5px 5px 0 0 rgba(0,0,0,0.2);
}
.school-intro3{
  border: 1px solid transparent;
  border-radius: 15px;
  background-color: rgba(255,255,255,0.1);
  box-shadow: 5px 5px 0 0 rgba(0,0,0,0.2);
}
.school-intro4{
  border: 1px solid transparent;
  border-radius: 15px;
  background-color: rgba(255,255,255,0.1);
  box-shadow: 5px 5px 0 0 rgba(0,0,0,0.2);
}

.school-intro5{
  border: 1px solid transparent;
  border-radius: 15px;
  background-color: rgba(255,255,255,0.1);
  box-shadow: 5px 5px 0 0 rgba(0,0,0,0.2);
}
.school-intro6{
  border: 1px solid transparent;
  border-radius: 15px;
  background-color: rgba(255,255,255,0.1);
  box-shadow: 5px 5px 0 0 rgba(0,0,0,0.2);
}
.school-intro7{
  border: 1px solid transparent;
  border-radius: 15px;
  background-color: rgba(255,255,255,0.1);
  box-shadow: 5px 5px 0 0 rgba(0,0,0,0.2);
}
li {
  font-size: 14px;
  margin-bottom: 5px;
}
</style>